import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { List } from '../../shared/models';
import { deEscapeHtml } from '../../shared/helpers/deEscapeHtml';
import { MedicalApplicationList } from '../store/medical-dashboard/medical-dashboard.models';
import { IMedicalApplication } from '../store/medical-application/medical-application.models';
import { take } from 'rxjs/operators';
import { MedicalAttachments, MedicalDocumentHistory } from '../store/medical-document-history/medical-document-history.models';
import { convertMomentDate } from '../../shared/helpers/date-format';
import { removeEmptyObjectValues, rmEmptyObjectValuesWithEmptyArray } from '../../shared/helpers/other';

interface MedicalAppDashboardFiltersDTO {
  search: string;
  statuses: string[];
  caregiverStatuses: string[];
  sales: string[];
  skillCategories: string[];
  assigned: number[];
  dateFrom: string;
  dateTo: string;
  page?: number;
  order?: string;
  dir?: string;
  perPage: number;
}

@Injectable({
  providedIn: 'root',
})
export class MedicalService {
  url = `${this.apiUrl}/v2/medical-application`;
  urlEmp = `${this.apiUrl}/v2/employee`;
  private isSetSignESignatureAgreement: BehaviorSubject<{ value: boolean; message: string }> = new BehaviorSubject({
    value: false,
    message: '',
  });
  isSetSignESignatureAgreement$: Observable<{ value: boolean; message: string }> = this.isSetSignESignatureAgreement.asObservable();

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  setSignESignatureAgreement(value: boolean, message: string): void {
    this.isSetSignESignatureAgreement.next({ value, message });
  }

  getMedicalAll(filters: MedicalAppDashboardFiltersDTO): Observable<List<MedicalApplicationList[]>> {
    return new Observable((observer: Observer<List<MedicalApplicationList[]>>) => {
      const newFilters = filters
        ? {
            ...filters,
            search: (deEscapeHtml(filters.search || '') as string) || null,
            'statuses[]': filters.statuses || null,
            'skillCategories[]': filters.skillCategories || null,
            'sales[]': filters.sales || null,
            'assigned[]': filters.assigned || null,
          }
        : {};
      delete (<any>newFilters).statuses;
      delete (<any>newFilters).sales;
      delete (<any>newFilters).position;
      delete (<any>newFilters).caregiverStatuses;
      delete (<any>newFilters).assigned;

      this.http
        .get<MedicalApplicationList[]>(`${this.url}`, {
          params: removeEmptyObjectValues(newFilters),
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<MedicalApplicationList[]>): void => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  getStatusesForMedical(): Observable<any> {
    return this.http.get<any[]>(`${this.url}/statuses`);
  }

  getMedicalDocumentHistoryById(uid: string, filters: any): Observable<any> {
    return new Observable((observer: Observer<List<MedicalDocumentHistory[]>>) => {
      const newFilters = {
        ...filters,
        search: (deEscapeHtml(filters.search || '') as string) || null,
        dateFrom: filters.date?.from ? convertMomentDate(filters.date?.from) : null,
        dateTo: filters.date?.to ? convertMomentDate(filters.date?.to) : null,
        'statuses[]': filters.statuses || null,
        'assigned[]': filters.assigned || null,
      };
      delete newFilters.statuses;
      delete newFilters.date;
      delete newFilters.assigned;

      this.http
        .get<MedicalDocumentHistory[]>(`${this.url}/${uid}/document-history`, {
          params: rmEmptyObjectValuesWithEmptyArray(newFilters),
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<MedicalDocumentHistory[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  createMedicalApplication(payload: IMedicalApplication): Observable<IMedicalApplication> {
    return this.http.post<IMedicalApplication>(`${this.url}`, payload);
  }

  createMedicalApplicationByUid(uid): Observable<IMedicalApplication> {
    return this.http.post<IMedicalApplication>(`${this.url}`, { uid });
  }

  saveMedicalApplication(payload: IMedicalApplication): Observable<IMedicalApplication> {
    return this.http.put<IMedicalApplication>(`${this.url}/${payload.id}`, payload);
  }

  getMedicalApplication(id: string): Observable<IMedicalApplication> {
    return this.http.get<IMedicalApplication>(`${this.url}/${id}`);
  }

  getMedicalApplicationWithHash(medicalId: string, hash: string): Observable<IMedicalApplication> {
    return this.http.get<IMedicalApplication>(`${this.urlEmp}/${hash}/medical-application/${medicalId}`);
  }

  deleteMedicalApplication(id: string | string[]): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  getEmployeeList(search: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/employee`, { params: { search } });
  }

  getUserList(search: string, forFilter: number): Observable<any> {
    return this.http.get<any[]>(`${this.url}/assign/users`, {
      params: {
        search,
        forFilter,
      },
    });
  }

  assignedUser(ids: string[], assignIds: string[], assignAllDocuments: number): Observable<any> {
    const data: any = {
      assignIds,
      ids,
    };
    if (assignAllDocuments) {
      data.assignAllDocuments = 1;
    }
    return this.http.post<any>(`${this.url}/assign`, data);
  }

  deleteAssignedUser(medicalId: string, userId: string, assignAllDocuments: number): Observable<void> {
    const params: any = {
      assignAllDocuments,
    };
    return this.http.delete<void>(`${this.url}/${medicalId}/assign/${userId}`, {
      params: params?.assignAllDocuments ? params : null,
    });
  }

  getDocumentTypes(medicalId: string): Observable<any> {
    return this.http.get<any>(`${this.url}/${medicalId}/documents`);
  }

  sendMedicalApplicationToSign(medicalId: string, payload: any): Observable<any> {
    return this.http.post<any>(`${this.url}/${medicalId}/send-for-sign`, payload);
  }

  approveMedicalApplication(medicalId: string): Observable<any> {
    return this.http.post(`${this.url}/${medicalId}/approve`, {});
  }

  setSignatureToMedicalApplication(medicalId: string, payload: any): Observable<IMedicalApplication> {
    return this.http.post<IMedicalApplication>(`${this.url}/${medicalId}/sign-document`, payload);
  }

  setSignatureToMedicalApplicationWithHash(medicalId: string, hash: string, payload: any): Observable<IMedicalApplication> {
    return this.http.post<IMedicalApplication>(`${this.urlEmp}/${hash}/medical-application/${medicalId}/sign-document`, payload);
  }

  signedMedicalApplication(medicalId: string): Observable<any> {
    return this.http.post(`${this.url}/${medicalId}/signed`, {});
  }

  signedMedicalApplicationWithHash(medicalId: string, hash: string): Observable<any> {
    return this.http.post(`${this.urlEmp}/${hash}/medical-application/${medicalId}/signed`, {});
  }

  downloadMedicalApplication(medicalId: string, type: string): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${this.url}/${medicalId}/download/${type}`);
  }

  sendToHHAMedicalApplication(medicalId: string): Observable<any> {
    return this.http.post(`${this.url}/${medicalId}/hha`, {});
  }

  // Attachments by uid
  getMedicalAttachmentsEmployee(uid: string, filters, isParent = 0): Observable<List<MedicalAttachments[]>> {
    return new Observable((observer: Observer<List<MedicalAttachments[]>>) => {
      const newFilters = {
        ...filters,
        dateFrom: filters.date?.from ? convertMomentDate(filters.date.from) : null,
        dateTo: filters.date?.to ? convertMomentDate(filters.date.to) : null,
        search: (deEscapeHtml(filters.search || '') as string) || null,
        isParent,
      };
      delete newFilters.date;

      this.http
        .get<MedicalAttachments[]>(`${this.urlEmp}/medical-application/${uid}/attachments`, {
          params: newFilters,
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<MedicalAttachments[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  downloadMedicalAttachmentsEmployee(idMedical: string, idAtt: string | string[]): Observable<any> {
    if (Array.isArray(idAtt)) {
      const params = { 'ids[]': idAtt };
      return this.http.get(`${this.urlEmp}/medical-application/${idMedical}/attachments/multiple`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.urlEmp}/medical-application/${idMedical}/attachments/${idAtt}`);
    }
  }

  updateMedicalAttachmentsEmployee(idMedical: string, idAtt: string, title: string): Observable<any> {
    return this.http.put(`${this.urlEmp}/medical-application/${idMedical}/attachments/${idAtt}`, { title });
  }

  deleteMedicalAttachmentsEmployee(idMedical: string, idAtt: string): Observable<void> {
    return this.http.delete<void>(`${this.urlEmp}/medical-application/${idMedical}/attachments/${idAtt}`);
  }

  // Attachment by id
  getMedicalAttachmentsById(id: string, filters, isParent): Observable<any> {
    return new Observable((observer: Observer<List<MedicalAttachments[]>>) => {
      const newFilters = rmEmptyObjectValuesWithEmptyArray({
        ...filters,
        dateFrom: filters.date?.from ? convertMomentDate(filters.date.from) : null,
        dateTo: filters.date?.to ? convertMomentDate(filters.date.to) : null,
        search: (deEscapeHtml(filters.search || '') as string) || null,
        isParent,
      });
      delete newFilters.date;
      this.http
        .get<MedicalAttachments[]>(`${this.url}/${id}/attachments`, {
          params: newFilters,
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<MedicalAttachments[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  downloadMedicalAttachments(idMedical: string, idAtt: string | string[]): Observable<any> {
    if (Array.isArray(idAtt)) {
      const params = { 'ids[]': idAtt };
      return this.http.get(`${this.url}/${idMedical}/attachments/multiple`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.url}/${idMedical}/attachments/${idAtt}`);
    }
  }

  updateMedicalAttachments(idMedical: string, idAtt: string, title: string): Observable<any> {
    return this.http.put(`${this.url}/${idMedical}/attachments/${idAtt}`, { title });
  }

  commentMedicalAttachments(idMedical: string, idAtt: string, comment: string): Observable<any> {
    return this.http.post(`${this.url}/${idMedical}/attachments/${idAtt}/comment`, { comment });
  }

  sendToHHSMedicalAttachments(idMedical: string, idAtt: string): Observable<any> {
    return this.http.post(`${this.url}/${idMedical}/attachments/${idAtt}/hha-sync`, {});
  }

  deleteMedicalAttachments(idMedical: string, idAtt: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${idMedical}/attachments/${idAtt}`);
  }

  getNotes(id: string, filters, isParent): Observable<List<any>> {
    return new Observable((observer: Observer<List<any[]>>) => {
      const newFilters = {
        ...filters,
        search: (deEscapeHtml(filters.search || '') as string) || null,
        isParent,
      };
      this.http
        .get<any[]>(`${this.url}/${id}/notes`, {
          params: removeEmptyObjectValues(newFilters),
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>): void => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  createNote(id: string, text: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/notes`, { text });
  }

  updateNote(id: string, text: string, noteId: string): Observable<any> {
    return this.http.put(`${this.url}/${id}/notes/${noteId}`, { text });
  }

  deleteNote(id: string, noteId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}/notes/${noteId}`);
  }

  changeDocumentDate(id: string, date: string, typeDate: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/date`, { [typeDate]: date });
  }
}
